// перечень путей проекта, выглядит как неимоверный костыль
// другими способами прокинуть наименования для крошек не получилось, meta не работают

// TODO при переезде на nuxt3 переписать на использование definePageMeta

export const PROJECT_PATHS = [
  {
    name: 'Главная',
    path: '/',
    to: '/',
  },
  {
    name: 'Найти исполнителя',
    path: 'search',
  },
  {
    name: 'Профиль',
    path: 'profile',
  },
  {
    name: 'Профиль',
    path: 'organization-profile',
  },
  {
    name: 'Создать заказ',
    path: 'order',
  },
  {
    name: 'О проекте',
    path: 'about',
  },
  {
    name: 'Политика обработки персональных данных',
    path: 'policy',
  },
  {
    name: 'Оставить заявку',
    path: 'ask',
  },
  {
    name: 'Контакты',
    path: 'contacts',
  },
  {
    name: 'Техническая поддержка',
    path: 'support',
  },
  {
    name: 'Stabil',
    path: 'stabil',
  },
  // лендинги
  {
    name: 'РЕХАУ.Про Качество',
    path: 'mobileapp',
  },
  {
    name: 'Бренд №1',
    path: 'reliable',
  },
  {
    name: 'DZR латунь',
    path: 'dzr-latun',
  },
  {
    name: 'Система Качества РЕХАУ',
    path: 'quality-control',
  },
  {
    name: 'R.Bonus',
    path: 'r-bonus',
  },
  {
    name: 'Обучение и клуб монтажников',
    path: 'education',
  },
  {
    name: 'R.Garant',
    path: 'r-garant',
  },
  /*
  catalog: {
    label: 'Каталог',
    path: '/catalog',
  },
  */
  // Дом, коттедж
  {
    name: 'Решения для домов',
    path: 'resheniya-dlya-domov',
  },
  {
    name: 'Водоснабжение',
    path: 'vodosnabzhenie',
  },
  {
    name: 'Система отопления',
    path: 'sistema-otopleniya',
  },
  {
    name: 'Обогрев открытых площадок',
    path: 'obogrev-otkrytykh-ploshhadok',
  },
  {
    name: 'Система управления микроклиматом',
    path: 'sistema-upravleniya-mikroklimatom',
  },
  {
    name: 'Канализация',
    path: 'kanalizacziya',
  },
  {
    name: 'Комплексные решения',
    path: 'kompleksnye-resheniya',
  },
  {
    name: 'Защита от протечек',
    path: 'zashhita-ot-protechek',
  },
  {
    name: 'Теплые полы',
    path: 'teplye-poly',
  },
  {
    name: 'Монтаж',
    path: 'montazh',
  },
  {
    name: 'Проектирование',
    path: 'proektirovanie',
  },
  {
    name: 'Ремонт',
    path: 'remont',
  },
  {
    name: 'Под ключ',
    path: 'pod-klyuch',
  },
  {
    name: 'Обслуживание',
    path: 'obsluzhivanie',
  },
  {
    name: 'Монтаж водяного пола',
    path: 'montazh-vodyanogo-pola',
  },
  {
    name: 'Монтаж водяного пола',
    path: 'montazh-elektricheskogo-pola',
  },

  // Квартиры
  {
    name: 'Решения для квартир',
    path: 'resheniya-dlya-kvartir',
  },
  {
    name: 'Водоснабжение',
    path: 'vodosnabzhenie',
  },
  {
    name: 'Как работать со сметами',
    path: 'estimates-and-calculations',
  },
  {
    name: 'Сметы',
    path: 'estimates-land',
  },
  {
    name: 'Калькулятор температурных удлинений',
    path: 'thermal-elongation-calculator',
  },
]
